import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './pvp.scss';
import { Alert, Col, Row } from 'react-bootstrap';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkePVPArenaTeams: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page pvp-nikke'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Meta Teams and Archetypes</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_pvp5.jpg"
            alt="PVP"
          />
        </div>
        <div className="page-details">
          <h1>Meta Teams and Archetypes</h1>
          <h2>The best teams to use in NIKKE PVP.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content with-italics">
        <SectionHeader title="Foreword" />
        <p>
          This guide has been created by <strong>awesome21snake</strong>. If you
          have any feedback about the guide, catch him on our Discord!
        </p>
        <SectionHeader title="SP Attack" />
        <p>
          In Arena, attackers are insanely favored to win. If both the attacker
          and defender are within CP range of each other, have all relevant
          Nikkes available, and have investments on all the important PvP
          Nikkes, if the attacker loses the matchup, it is 100% due to lack of
          sufficient knowledge about countering PvP teams (except in rare cases
          of rocket whiffing or known bugs). Even if you have one very strong
          team, attackers can make 2 attack teams instead of 3 and skip 1 of
          your defense teams to win easily, since that one strong team probably
          used up a lot of the Meta Nikkes. The following Team Archetypes can
          usually beat almost all of the existing defense setups:
        </p>
        <ul>
          <li>Scarlet + Jackal</li>
          <li>Biscuit + Anis:SS (or Biscuit + Alice)</li>
        </ul>
        <p>
          These teams are so insanely strong that we, as of Tia Recruitment
          Banner, do not have the units available to build 3 defense setups
          countering any of these 2 types of teams completely.
        </p>
        <p>
          There are also additional attacking setups that are important to point
          out. These possible alternatives (or used in conjunction) to the
          already listed 2 mega teams include:
        </p>
        <ul>
          <li>
            Use of Privaty's stun in: Privaty + Anis:SS or Privaty + Clip SG.
          </li>
          <li>Use of Rosanna to counter Jackal-link or 2B.</li>
          <li>2B or Maiden nuke setups to save Scarlet for another round.</li>
          <li>Use of Makima to counter SG teams or Alice.</li>
          <li>
            Use of fast Power setups to nuke relatively slow, non revive, solo
            B3 comps.
          </li>
          <li>Use of Emma to counter Machine Guns.</li>
        </ul>
        <p>Key Attacking Strategy if possible without CP deficit:</p>
        <p>
          If there is no Nikke on P2, the burst gen of the defending team’s RL
          is 2× instead of 4×, and hence the defending team’s burst gen is
          greatly reduced. Therefore, on the premise of there being no CP
          penalty, you can use this method of not having a p2 Nikke, running a
          4v5, and win against high-speed teams with multiple RL. If there is a
          CP Penalty, this strategy is strongly discouraged.
        </p>
        <SectionHeader title="SP Defence Theory" />
        <p>
          As explained in SP Attack In A Nutshell, if you wanted to take
          someone's spot, the easiest way usually is to do so is by using the
          following two teams:
        </p>
        <ul>
          <li>Scarlet Jackal one of their team setups,</li>
          <li>Biscuit SAnis or Biscuit Alice one of the other ones.</li>
        </ul>
        <p>
          In other words, the two most threatening teams to a defense setup are
          Scarlet Jackal and Biscuit SAnis. However, it will quickly become
          apparent that Scarlet Jackal is basically impossible to account for.
          Scarlet Jackal simply has far too many permutations to ever dream of
          countering on just one team, let alone three! Therefore, you should
          never try to counter Scarlet Jackal specifically. It is far more
          fruitful to try and counter Biscuit SAnis on three different teams
          instead.{' '}
        </p>
        <p>
          <em>
            Fortunately, if you manage to counter Biscuit SAnis, you'll counter
            the large majority of alternative teams in the process.
          </em>
        </p>
        <p>
          <strong>
            This is the basis on which the meta defense, as of Tia Recruitment
            Banner, is based on. We will now move on to discuss the current Meta
            Team Archetypes, listing teams, how they work against opponents, and
            how they may be countered.
          </strong>
        </p>
        <ul>
          <li>SG = Clip SG ideally.</li>
          <li>
            B2/B3/Flex = B2/B3 or Any Burst type unit with Good/High Burst Gen
            ideally.
          </li>
          <li>B1 = B1s listed in ‘“Not” a Tier List’ ideally.</li>
          <li>xAnne, xRupee = Anne: Miracle Fairy, Rupee:Winter Shopper.</li>
          <li>SMary, SAnis = Mary: Bay Goddess, Anis: Sparkling Summer</li>
          <li>
            Specialist usage is only for the teams they are mentioned in
            ideally.
          </li>
        </ul>
        <p>
          Thanks again to <strong>cleancascade</strong> for his insane
          contributions in this article
        </p>
        <SectionHeader title="Meta Team Archetypes" />
        <p>
          To have a general idea on how to counter meta teams, check out
          Keripo's PvP Meta Countering video:
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="6b7y97S_s0k" />
          </Col>
        </Row>
        <SectionHeader title="Team Archetype I - Scarlet Jackal Teams" />
        <h5>2RL Scarlet Jackal</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
        </div>
        <p>Or</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
        </div>
        <p>
          The fastest Scarlet team in the meta. Standard teams are 3RL, so this
          team can nuke the vast majority of teams before they access their B1.
          The first variant with XAnne is most common. The clip SG versions are
          particularly weak to Jackal comps, but free up many valuable units
          while still demanding Jackal to counter it. Putting Jackal in P1 is
          ideal since it means the opponent might whiff when they fight it.
        </p>
        <p>
          <strong>How to beat:</strong> If you're fighting the XAnne variant,
          mirror it with Noah or Blanc (with quantum cubes) as your main B2.
          Double clip SG version should die to most Scarlet Jackal comps.
          Another answer that saves your Scarlet is a 2.5RL speed full RL team
          with Noise (e.g. Noise Power/Laplace/Harran Noah Centi Jackal). This
          team should be able to block 2RL speed Nukes with Noise followed by
          shielding up with Noah, and then you win from there. 2RL Alice comps
          which block the Scarlet nuke Noah, or indom your Alice with Blanc,
          also win easily against this comp. There are some counters involving
          SAnis which can win against low level Scarlet S2s, which may involve
          the use of Blanc (SAnis Blanc Centi Anis Jackal).
        </p>
        <h5>2.5RL Scarlet Blanc Jackal</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Fast team that aims to get indom onto Scarlet ASAP, then Scarlet drops
          a boosted nuke on the opponent. Even if Scarlet is blocked / the
          opponent nukes you back, Blanc's indom will keep Scarlet in the fight.
          You can think of this team as a less glass-cannon version of 2RL
          Scarlet nuke. This team can still reach 2RL through Jackal feed or
          quantum cube. Anis goes into P3 so that she's least likely to steal
          indom from Scarlet. This is a lot more threatening than XAnne 2RL.
        </p>
        <p>
          <strong>How to beat:</strong> Any stall-heavy 3RL Noise Noah Jackal
          comp should be able to beat this fine. RLs are good options since they
          don't feed and won't get evaporated by Scarlet S2. 2RL Noah Scar
          Jackal might also work if it blocks Scarlet. The 2RL version of this
          comp (due to quantum cube) can be beaten by using 2RL Noah Alice,
          since Noah blocks Scarlet, and Alice starts killing opponents from P5.
          Defending Scarlet is P1 or P2 because you need Indom on scarlet. Since
          Scarlet has indomitability and not invincibility, the Jackal linked
          Nikkes will suffer at the hands of Alice.
        </p>
        <h5>2.5RL Scarlet Pepper Jackal</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Although this is a 2.5RL team, it essentially functions as a 2RL team
          unless the opponent uses all RL/SR against it. This is another fast
          nuke comp that's a bit cheaper. Pepper also buffs Scarlet, which is
          nice. If you're able to link XAnne instead of Pepper, this team
          becomes significantly stronger.
        </p>
        <p>
          <strong>How to beat:</strong> This has more weaknesses than the 2RL
          comp--both 2RL and some 3RL Scarlet Jackal comps can beat this setup
          since Pepper will trigger many Scarlet S2 procs along with Jackal S1
          procs.
        </p>
        <h5>3RL Scarlet Jackal</h5>
        <p>
          You can fit many things into this setup, so I won't bother covering
          them all. These teams are not as imposing as the 2-2.5RL setups, but
          they are still difficult to fight without using Jackal. Use if you
          want more freedom for your other two teams to trick the opponent.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
              <div className="placeholder-box">B1</div>
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
        </div>
        <p>
          AKA classic comp. Broken in rookie, nothing changes in SP. Issue is
          your other two teams will suffer hard if you use this. Can substitute
          SMary/Frima for a ghetto version of classic comp, but it won't be
          nearly as strong.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A weaker version of classic comp. This is basically a 2.5RL comp if
          Jackal is fed, so this team is quite fast and can fight many Scarlet
          Jackal setups.
        </p>
        <p>
          <strong>How to beat:</strong> The highest success rate against these
          comps is by using Rosanna’s specialist ability to remove Jackal link,
          which will come in clutch against this team. Noise Rosanna SAnis
          Biscuit Jackal, where Noise and SAnis are linked to Jackal should win
          9 times out of 10 against this team. Remember to upgrade Rosanna’s S1
          to lvl 4 to dispel opponent’s Jackal link. Another way to beat this
          might involve some RNG, but it does work effectively. Blanc/Noah
          Biscuit Rapunzel Alice Maxwell/Flex or Blanc Alice Rapunzel Biscuit
          Flex is a 3.5-4RL team that works on the basis of Rapunzel surviving
          Scarlet’s slash and Blanc/Noah getting Immunity because of Biscuit.
          Maxwell also needs to survive if you’re running her. Usually, an
          invested Rapunzel will survive unless she gets crit-ed by Scarlet’s
          burst. Alice dies to Scarlet slash but is revived by Rapunzel, who
          will start blasting and kill everyone in the opponent team. These
          teams are known as Super-Alice teams.
        </p>
        <h5>Other examples of 3RL Scarlet Jackal</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="folkwang" enablePopover />
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Stall comp that aims to make Scarlet unkillable for ~10s. Can also
          lose to 2RL nuke comps. Folk version loses to Biscuit Noah setups, but
          is a super cost-effective knowledge check otherwise. Blanc version is
          a bit harder to fight, but is more expensive.
        </p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 1/3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="laplace" enablePopover />
              <NikkeCharacter mode="icon" slug="power" enablePopover />
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Cheap 3RL Scarlet XAnne nuke if you don't use Noise for protection.
          Limits feeding against Jackal, so it can outspeed many teams that do
          feed it. Still loses to 2RL Scarlet variants.
        </p>
        <Alert variant="primary">
          <p>
            Note: All above Scarlet-Jackal teams can also use 2B, or Maiden (or
            even Harran) in place of Scarlet, which allows you to run non-Jackal
            Scarlet comps instead. This can create tricky defense setups that
            might throw your opponents off.
          </p>
        </Alert>
        <SectionHeader title="Team Archetype II - Biscuit Teams" />
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Popular stall comp that is often paired with 2RL Scarlet XAnne. The
          SAnis version is particularly nasty to fight. Even if you do kill
          Noise before she can burst, Rapunzel will just revive her. Same
          applies if you use Power to snipe the B3. This is one of the best
          teams to use if you CP gap the opponent, since this team becomes
          unbeatable if the opponent gets stat penaltied.
        </p>
        <p>
          <strong>How to beat:</strong> Blanc SAnis stall runs over this team,
          especially the Alice version. Otherwise, a faster, strong Scarlet nuke
          that one shots Rapunzel will guarantee victory.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
              <NikkeCharacter mode="icon" slug="nero" enablePopover />
              <NikkeCharacter mode="icon" slug="makima" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
              <NikkeCharacter mode="icon" slug="maiden" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Stall comp that has insane offensive capability. Blanc buffed SAnis is
          arguably the best stall-breaker that isn't Scarlet Blanc, so once this
          team gets to full burst, it probably will just win. Blanc will often
          indom Biscuit even if she does die and get revived, so your P3~P5 is
          basically untouchable without an AoE nuke. Nero/Makima won't pack as
          much firepower, but are viable alternatives if you want to save Blanc
          for another team. Privaty slows the team down which makes Noah less
          effective as a counter. Maiden speeds the team up and puts immense
          pressure on their P5, but requires Burst at level 9 for SAnis. Both
          versions are viable as a defense team.
        </p>
        <p>
          <strong>How to beat:</strong> SAnis, Privaty, and Maiden are all hard
          countered by Scarlet Jackal, so that's the simplest way to beat this
          team. Scarlet Blanc will also beat SAnis, especially if she doesn’t
          get indom by Blanc.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          The OG Biscuit comp. Flex spot can be anything from Noir to buff
          Alice, Maiden to take advantage of SAnis S1, Laplace for more splash
          damage, or Centi/Anis for more speed. This team becomes 3RL with
          Centi/Anis, but you'll need a Quantum cube if you're using Alice.
          SAnis variant is stronger if she's Burst 9.
        </p>
        <p>
          <strong>How to beat:</strong> Alice's version loses to P1
          Defender/Taunt with Biscuit, or the SAnis Blanc comp mentioned above.
          Scarlet nuke also wins if Rapunzel is killed before she can revive, or
          just generally to a faster Scarlet Jackal since SAnis can die to
          Scarlet S2.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A cost-effective Scarlet stall comp that doesn't use Jackal. This is a
          3RL team with low feed, so it can actually fight 3RL Scarlet Jackal
          comps pretty well. This comp is strong both offensively and
          defensively, so it will beat the large majority of non-Jackal teams.
        </p>
        <p>
          <strong>How to beat:</strong> 2RL Scarlet Jackal comps will win if
          Rapunzel is one shot. Fast SAnis Noah comps can also beat this team
          comfortably, since Scarlet will just get nuked by SAnis S2 as soon as
          Noah shield wears off.
        </p>
        <h5>Team #5</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="ludmilla" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="winter-shopper-rupee"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
              <div className="placeholder-box">SG</div>
            </div>
          </div>
        </div>
        <p>
          This team abuses Biscuit's kit to the fullest. Biscuit's burst is a
          powerful ATK buff + life-steal hybrid for supporters, which synergizes
          perfectly with SAnis. SAnis will become pseudo invincible during burst
          due to how much damage she pumps out, while the B1 Defender protects
          the rest of the team via taunt. Ludmilla is the safest option due to
          her long and continuous taunt. XRupee synergizes heavily with SAnis,
          and can also allow for Pepper to burst if she's your SG of choice. The
          flex spot can be Anis/Centi if you want to make this team 3RL, which
          IMO is scarier to fight than the slower variants with Privaty. Maiden
          pairs well with SAnis too.
        </p>
        <p>
          <strong>How to beat:</strong> It's SAnis, so Scarlet Jackal will eat
          this alive. You can also mirror this team using Noise instead of a
          flex/SG, and this allows you to beat this team at its own game since
          your SAnis will outheal the opponent. Privaty can make a special
          appearance here as well perhaps. SAnis won’t be able to self heal
          while she is stunned, so a Privaty+SAnis comp can possibly beat this
          comp as well.
        </p>
        <h5>Team #6</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="ludmilla" enablePopover />
            </div>
          </div>
        </div>
        <p>
          A better version of the team described above. However, it crucially
          needs Quantum cubes for more speed. Base speed is 4RL. With 2 lvl3
          quantum cubes on Noise/Biscuit and Anis, it becomes 3.5RL. With 3 lvl7
          quantum cubes, it becomes a 3RL, and hence becomes extremely viable as
          a defense team. Noise blocks AoE, Ludmilla has a permanent long taunt
          and is invincible, Biscuit gives SAnis insane self heal capability and
          ATK buff, which Noise also benefits from.
        </p>
        <p>
          <strong>How to beat:</strong> Again, it’s SAnis, so Scarlet Jackal
          will eat this alive. Quantum 7 is an extremely expensive requirement,
          so you might not even need Jackal. 2.5-3RL nukes will easily be able
          to beat this team since SAnis dies before Noise bursts.
        </p>
        <SectionHeader title="Team Archetype III - Revive Strats" />
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="employee-container for-nikke">
              <div className="placeholder-box">Flex</div>
              <div className="placeholder-box">SG</div>
            </div>
          </div>
        </div>
        <p>
          AKA Superalice. This team is basically a nuclear bomb waiting to
          happen. If Alice is killed and revived by Rapunzel, she will
          absolutely destroy the opposing team due to Blanc buff. The flex is
          often Noir, but just about anyone can work since this is basically a
          4-man team. SAnis cannot replace Alice effectively in this team, since
          her higher base HP makes it harder for Blanc to indom her (notably an
          issue against Scarlet nuke).
        </p>
        <p>
          <strong>How to beat:</strong> As usual, Alice can be countered by
          Defender P1+Biscuit comp. Rapunzel can also be sniped by Scarlet to
          guarantee a victory. SAnis Blanc stall will also win against this,
          since Alice will just be hunted down repeatedly.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Generalized team setup that usually will end up as 3RL. The idea is
          that you sell off your main DPS in P1, then revive them with Rapunzel.
          Blanc/XAnne/Folkwang/Poli then buffs them to create your win
          condition. Good B3 options include Scarlet, Maiden, or non-RL/SR to
          avoid target skipping. Blanc and XAnne create the most threat.
          Folkwang is a good knowledge-check. This comp is great in conjunction
          with a Scarlet Jackal team + a Biscuit setup.
        </p>
        <p>
          <strong>How to beat:</strong> If the B3 is an AoE nuker, using Noah to
          block them will greatly increase your chances of winning. 3RL Noah
          Biscuit comps in general are good options against this setup. Scarlet
          Jackal setups will also blow this comp up, but you will want to bring
          some sort of defense against AoE nuke if Scarlet is the B3.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="viper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This team uses Viper as a sacrifice, then revives her with XAnne while
          buffing your main B3. This team works best with AoE B3s like Maiden,
          Harran, or Scarlet. Great T3 option since it doesn't conflict with
          many T1/T2 setups, and is probably the strongest Maiden/Harran comp
          that doesn't use Jackal. Requires heavy B3 investment for best
          results.
        </p>
        <p>
          <strong>How to beat:</strong> Scarlet Jackal runs it over, especially
          if you bring Maxwell/Alice as a flex to soften up Noise for a
          guaranteed one-shot. This team also banks on you killing Viper early,
          so slow Alice/SAnis teams that can survive the nuke will do well,
          especially if you manage to cut XAnne off to reduce their threat.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
              <div className="placeholder-box">B3</div>
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Requires Quantum Relic cube on Centi & Rapunzel. An expensive but
          stacked team that comfortably fights most non-Jackal comps. Noise acts
          as the main tank, while Blanc buffs the team and will indom whoever is
          catching the most heat (usually Noise or Scarlet). Rapunzel serves as
          backup and will revive Noise if she does die before burst. This is a
          nuke team with a nasty catch, and it will absolutely crush slow teams
          that challenge it. Faster SAnis/Alice comps generally have to
          sacrifice longevity for speed, which makes Blanc's indom even more
          problematic for them to deal with.
        </p>
        <p>
          <strong>How to beat:</strong> Scarlet Jackal is the main solution. A
          stacked Jackal Alice comp with Noah will work if set up correctly.
          Blanc SAnis also stands a good chance of winning against this since no
          Jackal link.
        </p>
        <Alert variant="primary">
          <p>
            <strong>
              While team building, the concept of the current meta is still the
              same as was described at the start. We believe there needs to be
              one Scarlet and one SAnis team, followed by a 3rd team, for which
              you have tons of choices, none as effective as the other two teams
              though.
            </strong>
          </p>
        </Alert>
        <SectionHeader title="Team Archetype IV - Leftovers" />
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="makima" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
        </div>
        <p>
          General team format. The first flex is often going to be a B2 who
          won't conflict. P2 is the most vulnerable spot in a Makima team, so
          that unit is the easiest to kill via RL splash. After that, you just
          need a threatening B3. SAnis fits the bill the most, but you may want
          to ensure the team is 3RL if you use her. Power and Pepper can also be
          used if you reorder the team or omit Noise.
        </p>
        <p>
          <strong>How to beat:</strong> The usual counter to Makima is just
          Scarlet Jackal. Since these comps are using leftover units, specific
          unit counters apply. It just depends on who the B3 is (ex: Power will
          lose to revive, shotgun/Privaty can be Biscuit'd, etc).
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="makima" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
        </div>
        <p>Or</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
        </div>
        <p>
          AKA Shotgun Privaty & an example of the above team format. Again, P2
          is vulnerable, but it can be Anis for speed or just another SG (move
          Privaty to P2 if it's a B3 SG). The SGs will pressure the opponent's
          P5 at first, but the whole team will target P1 during stun in most
          cases. This can catch people who aren't well-versed in stun mechanics
          off-guard. Makima buys you time for you to set up Privaty stun for a
          win condition. The Jackal version puts far more pressure onto the
          opponent.
        </p>
        <p>
          <strong>How to beat:</strong> There are a few easy answers to this.
          Generally top tier teams will win, such as Jackal comps. Defender X
          Biscuit X Defender is also strong if you can secure your win-condition
          before burst comes out. However, the best answer is just B3 X Biscuit
          X Blanc. Biscuit guards Blanc in your P5, while Blanc guards your P1
          with indom. This gives full protection against the team as long as you
          Blanc burst before Privaty stun. Against the Jackal variant, this is a
          bit harder since speed is more of a factor.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
              <div className="placeholder-box">Flex</div>
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
              <div className="placeholder-box">Flex</div>
            </div>
          </div>
        </div>
        <p>
          Stall team that makes use of Noise/Noah if you're using Blanc Biscuit
          + 2RL Scarlet. You will absolutely want this to be a 3RL comp. SAnis
          works best as the B3, but Alice can work as well. Noir is a great flex
          option here. Pepper is used to ensure your burst goes through even if
          Noise dies. The order needs to change if you spend Anis/Centi
          here--usually Noah will end up in P1-P2. If you're running Noise +
          Pepper, you can opt for Scarlet here as well.
        </p>
        <p>
          <strong>How to beat:</strong> Again, specific unit counters apply here
          since this is a general team setup. Noise can be rushed down by
          shotgun spam if she's unlucky. Generic strong teams like Alice/SAnis
          or Scarlet Jackal will likely win.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
        </div>
        <p>
          AKA Shotgun Alice. The concept behind this team is simple: Alice puts
          pressure on the opponent's P1, while the SGs put pressure on their P5.
          Noah buys time for the team to safely shoot at the opponent since it
          doesn't have a healer. Using XAnne instead gives you a higher threat,
          but makes the team far more vulnerable to Noah. The two optimal SGs
          for this are Noir + Drake, but Drake can be replaced by whoever your
          strongest SG is. The usual Alice counters are hard to use against this
          team because P1 is not the only position being focused down. The team
          is also capable of reaching 2-2.5RL depending on how much Jackal gets
          fed.
        </p>
        <p>
          <strong>How to beat:</strong> Scarlet Jackal is the best way to combat
          this team, since it relies heavily on double SG to do its job. If you
          want to go the Biscuit route, you will likely need to use something
          like Blanc SAnis Biscuit X Defender--the idea being that Blanc indoms
          your P5 taunt defender so that they can survive long enough for SAnis
          to solo the opponent. Noah Alice Biscuit Jackal is also a good option,
          and can probably win without Jackal if tweaked properly.
        </p>
        <h5>Team #5</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="2b" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          General team setup. Buffed 2B Burst to have as a 3rd team setup. Flex
          can be SGs to put pressure on the opponent to block both 2B AoE and SG
          onslaught from P5. Can be used in attack as well since 2B hits Jackal
          link very hard. Example: 2B Jackal SG SG Noah.
        </p>
        <p>
          <strong>How to beat:</strong> 2B is hard countered by Rosanna, who
          removes 2B’s self ATK Buff which 2B gets from her skill 2. Also hard
          countered by Noah since 2B’s AoE is blocked. Also hard countered by
          Biscuit+Ludmilla/Nero, due to the nature of how distributed damage
          works. If distributed damage targets a Nikke while it is Invincible,
          it will do no damage.
        </p>
        <h5>Team #6</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="2b" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="placeholder-box">B1</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noir" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Powerful SAnis counter, mostly used in attack, can be used as 3rd team
          perhaps. Blanc will indom Noir while Biscuit protects 2B. B1 can be
          Noise to buff 2B, Pepper to nuke highest ATK, or just a Burst gen
          filler like Pascal.
        </p>
        <p>
          <strong>How to beat:</strong> By using Rosanna, Noah or
          Biscuit+Ludmilla, as describe in the previous guide.
        </p>
        <h5>Team #7</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
              <NikkeCharacter mode="icon" slug="poli" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maiden" enablePopover />
              <NikkeCharacter mode="icon" slug="power" enablePopover />
              <div className="placeholder-box">B3</div>
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
        </div>
        <p>
          P1 is safe due to immunity from Biscuit. B3 can be Maiden for nuke or
          Power to snipe someone. Pepper helps Power to snipe Highest ATK Nikke.
        </p>
        <p>
          <strong>How to beat:</strong> SAnis should be able to beat this with
          ease. Power can be countered by using Rapunzel or xAnne to revive the
          B3 after it dies. Makima can block P5 against this team to block Poli,
          Pepper and other SGs.
        </p>
        <h5>SG Comps</h5>
        <p>
          Shotgun teams were used a lot in old meta, but are quite restricted to
          use in the new meta. However, they are still an excellent choice for
          3rd teams that anyone can use. <strong>How to beat</strong>: Usually,
          Makima is enough to keep them in place, but Jackal comps are also
          highly effective due to the high amounts of feed that SGs give.
        </p>
        <p>Examples:</p>
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="blanc" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="maiden" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Very basic fast Maiden nuke. Blanc is protected by Biscuit, and buffs
          Maiden’s burst. Meanwhile since it is an SG comp, it pressures P5 as
          well.
        </p>
        <h5>Team #2</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
        </div>
        <p>Or</p>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="miracle-fairy-anne"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Simple old Privaty Revive strat stun setups. Can allow SGs to target
          skip from Makima blocking them in P5, but is heavily RNG based.
          Regardless, it counters the general non meta teams extremely well.
        </p>
        <h5>Team #3</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
        </div>
        <p>
          Noise blocks the AoE, and then the SGs unload everything into the
          enemy team. Nothing else to really say about this.
        </p>
        <h5>Team #4</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="makima" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="placeholder-box">B2</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
              <div className="placeholder-box">SG</div>
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Makima saves P1 while Privaty or SG charge Burst Gen. Privaty can
          stun, otherwise use of Maiden for nuke.
        </p>
        <h5>Team #5</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="jackal" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Very strong team 3 for both defense and attack. Alice focuses P1/P5
          and SGs target P5/P1 depending on whether you are defending or
          attacking respectively. Puts pressure on the opponent to block both P5
          and P1 positions, along with Noah blocking AoE, hence giving
          survivability. Probably the strongest SG team setup.
        </p>
        <SectionHeader title="Final Thoughts on PvP" />
        <p>
          I hope this article gave you something to think about, or helped you
          win your attacks against or successfully defend against your opponents
          in your PvP bracket. I thank cleancascade again for his hard work in
          helping me put out this insane How-to-Meta in PvP guide. As you must
          have realized by now, Scarlet Jackal is probably countering everything
          and anything that came in front of it. It is insanely strong and we
          think it is best to just not think about countering very fast Scarlet
          comps. Instead, we believe it is more fruitful if we try to counter
          other existing comps, such as Biscuit+SAnis/Alice, relatively slower
          Scarlet comps, 2B’s AoE, Power’s single target nuke etc. The most
          important of which is Biscuit+SAnis, which was kept in mind when we
          worked towards the ‘current meta’.
        </p>
        <p>
          We currently believe that there just aren’t enough “PvP Nikkes” as of
          Tia Recruitment Banner to make PvP an extremely tough section.
          Attackers just have too big of an advantage over Defenders, and the
          only way to make it interesting at this point is if the Attacker lacks
          critical meta Nikkes. The best defense for now is simply if you manage
          to CP Gap your opponents. However, it never hurts to try finding the
          perfect defense. I hope this guide helped you to understand what to
          look out for in PvP team building. Join us in the{' '}
          <a
            href="https://discord.com/invite/Gpt2KykNpw"
            target="_blank"
            rel="noreferrer"
          >
            Nikke Community Discord
          </a>{' '}
          or Prydwen’s Discord Server if you would like to discuss PvP directly
          with us!
        </p>
        <p>
          It has been a long journey since I started making these guides, but I
          believe this is all. This is <strong>awesome21snake</strong>, signing
          out!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
    </DashboardLayout>
  );
};

export default NikkePVPArenaTeams;

export const Head: React.FC = () => (
  <Seo
    title="Meta Teams and Archetypes | NIKKE | Prydwen Institute"
    description="The best teams to use in NIKKE PVP."
    game="nikke"
  />
);
